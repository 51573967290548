<template>
    <div>
      <!-- <h3 class="mb-2" :style="{color: type === 'min' ? 'red' : siteColor}">{{ type === 'min' ? 'Hot' : 'Cold'}}</h3> -->
      <v-data-table
      :headers="productHeaders"
      :items="currentSensorProducts"
      :items-per-page="editing ? -1 : -1"
      item-key="id"
      item-value="id"
      class="elevation-1"
      :sort-by="editing ? '' : 'name'"
      :sort-desc="editing ? true : false"
      :page="editing ? currentPage : undefined"
      :disable-pagination="editing"
      :disable-sort="editing"
      id="products"
      @update:page="tableUpdate"
      >
          <template v-if="currentSensorProducts.length && type === 'min'" v-slot:body="{items}">
              <tbody>
                  <template v-for="(item,i) in items">
                      <tr :class="!currentProduct ? '' : currentProduct.id === item.id ? 'current-product-min' : ''" style="cursor: pointer;" :key="item.id">
                        <td><v-icon class="hot-icon" @click="setProduct(item)" color="#c40000">mdi-chart-timeline-variant</v-icon></td>
                        <td>
                            <v-edit-dialog
                                @save="editItem(item, 'name')"
                                large
                                @close="closeItem()"
                                @open="setItem(item)"
                                :color="siteColor"

                            >
                                <v-text-field
                                :color="siteColor"
                                v-model="item.name"
                                hide-details
                                ></v-text-field>
                                <template v-slot:input>
                                <v-text-field
                                :color="siteColor"
                                v-model="item.name"
                                hide-details
                                ></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </td>
                        <td>
                            <v-text-field
                                hide-details
                                single-line
                                @click.stop="setItem(item)"
                                @change="editItem(item, 'min')"
                                type="number"
                                :color="siteColor"
                                v-model="item.min"
                            ></v-text-field>
                        </td>
                        <td><v-icon color="red" @click="confirmDelete(item.id)">mdi-hamburger-remove</v-icon></td>
                        <!-- <td>
                            <v-text-field
                                hide-details
                                single-line
                                @click.stop="setItem(item)"
                                @change="editItem(item, 'low')"
                                type="number"
                                :color="siteColor"
                                v-model="item.low"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                hide-details
                                single-line
                                @click.stop="setItem(item)"
                                @change="editItem(item, 'high')"
                                type="number"
                                :color="siteColor"
                                v-model="item.high"
                            ></v-text-field>
                        </td> -->
                      </tr>
                  </template>
              </tbody>
          </template>
          <template v-else-if="currentSensorProducts.length && type === 'range'" v-slot:body="{items}">
              <tbody>
                  <template v-for="(item,i) in items">
                      <tr :class="!currentProduct ? '' : currentProduct.id === item.id ? 'current-product-range' : ''" style="cursor: pointer;" :key="item.id">
                        <td><v-icon class="cold-icon" @click="setProduct(item)" :color="siteColor">mdi-chart-timeline-variant</v-icon></td>
                        <td>
                            <v-edit-dialog
                                :return-value.sync="item.name"
                                @save="editItem(item, 'name')"
                                large
                                @open="setItem(item)"
                                :color="siteColor"
                            >
                                <v-text-field
                                :color="siteColor"
                                v-model="item.name"
                                hide-details
                                ></v-text-field>
                                <template v-slot:input>
                                <v-text-field
                                :color="siteColor"
                                v-model="item.name"
                                hide-details
                                ></v-text-field>
                                </template>
                            </v-edit-dialog>
                            </td>
                        <!-- <td>
                            <v-text-field
                                hide-details
                                single-line
                                @click.stop="setItem(item)"
                                @change="editItem(item, 'min')"
                                type="number"
                                :color="siteColor"
                                v-model="item.min"
                            ></v-text-field>
                        </td> -->
                        <td>
                            <v-text-field
                                hide-details
                                single-line
                                @click.stop="setItem(item)"
                                @change="editItem(item, 'low')"
                                type="number"
                                :color="siteColor"
                                v-model="item.low"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                hide-details
                                single-line
                                @click.stop="setItem(item)"
                                @change="editItem(item, 'high')"
                                type="number"
                                :color="siteColor"
                                v-model="item.high"
                            ></v-text-field>
                        </td>
                        <td><v-icon color="red" @click="confirmDelete(item.id)">mdi-hamburger-remove</v-icon></td>
                      </tr>
                  </template>
              </tbody>
          </template>
          <template v-else v-slot:no-data><span>No Products Found</span></template>
      </v-data-table>    
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  export default {
    name: "products",
    data() {
      return {
        currentProduct: "",
        editing: false,
        currentPage: 1,
        minProductHeaders: [
          { text: "", value: "", width: "5%" },
          { text: "Name", value: "name"},
          { text: "Min Temp (°F)", value: "min", width: "25%" },
          { text: "", width: "5%" },
        //   { text: "Low (°F)", value: "low", width: "25%" },
        //   { text: "High (°F)", value: "high", width: "25%", },
          // { text: "End", value: "resolved" },
        //   { text: "Type", value: "alarm.type" },
        //   // { text: "Status", value: "status" },
        //   // { text: "Trigger", value: "alarm.value" },
        //   { text: "Result", value: "result" },
        //   { text: "Resolution Time", value: "" },
        //   // { text: "Notifications", value: "" },
        //   { text: "User Response", value: "" },
        //   { text: "Notes", value: "notes" },
        ],
        rangeProductHeaders: [
            { text: "", value: "", width: "5%" },
            { text: "Name", value: "name"},
            { text: "Low (°F)", value: "low", width: "20%" },
            { text: "High (°F)", value: "high", width: "20%", },
            { text: "", width: "5%" },
        ]
      };
    },
    components: {},
    watch: {
        currentItem(item) {
            this.currentProduct = item
        }
    },
    props: ["currentItem", "type"],
    async created() {
    },
    computed: {
      ...mapState("sensor", ["sensor", "sensorProducts"]),
    //   eventsByDate() {
    //     if (!this.sensor.length) return []
    //     else return this.sensorEvents.filter(item => item.createdAt >= this.sensor[0].createdAt)
    //   }
        productHeaders() {
            if (this.type === "range") return this.rangeProductHeaders
            else return this.minProductHeaders
        },
        rangeSensorProducts() {
            return this.sensorProducts.filter(item => item.type === "range")
        },
        minSensorProducts() {    
            return this.sensorProducts.filter(item => item.type === "min")
        },
        currentSensorProducts() {
            if (this.type === "range") return this.rangeSensorProducts
            else return this.minSensorProducts
        }
    },
    methods: {
        confirmDelete(id) {
            this.$swal({
            title: 'Remove Product',
            text: "Are you sure you want to remove this product?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#4baf4f',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            'cancelButtonText': 'No',
            'iconColor': '#d33'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await this.deleteProduct(id)
                }
            });
        },
        async deleteProduct(id) {
            try {
                await this.$store.dispatch("sensor/removeProduct", id)
            } catch (error) {
                console.log("error deleting product")
            }
        },
        tableUpdate(val) {
            this.currentPage = val
        },
        setProduct(item) {
            this.currentProduct = item
            this.$emit('setProduct',item)
        },
        setItem(item) {
            this.editing = true
            this.currentProduct = { ...item }
        },
        closeItem() {
            this.editing = false
        },
        async editItem(item, prop) {
            let res = await this.$store.dispatch("sensor/changeProduct", {
                id: item.id,
                prop,
                val: prop === "name" ? item[prop] : Number(item[prop])
            })
            if (res) {
                this.$emit("updateProduct", {
                    product: item,
                    prop
                })
            }
        },
    //   checkMessages(messages) {
    //     let msgs = messages.filter(msg => msg.userResponse)
    //     if (msgs.length) return  msgs.map(msg => `${msg.responseBy}: ${msg.userResponse} (${this.lastUpdated(msg.updatedAt)})`).join('<br>')
    //     else return "NA "
    //   },
    //   lastUpdated(date) {
    //     let fullDate = new Date(date).toLocaleDateString();
    //     let todayDate = new Date().toLocaleDateString();
    //     if (fullDate === todayDate) {
    //       return `${new Date(date).toLocaleTimeString()}`
    //     }
    //     else return `${fullDate} - ${new Date(date).toLocaleTimeString()}`;
    //   },
    //   updateNotes(item) {
    //     this.$swal({
    //       input: "textarea",
    //       // inputLabel: "Event Notes",
    //       inputPlaceholder: "Enter notes here...",
    //       inputAttributes: {
    //         "aria-label": "Enter notes here"
    //       },
    //       inputValue: item.notes ? item.notes : "",
    //       title: 'Event Notes',
    //       // icon: 'info',
    //       showCancelButton: true,
    //       confirmButtonColor: '#4baf4f',
    //       cancelButtonColor: '#d33',
    //       showLoaderOnConfirm: true,
    //       confirmButtonText: 'Update',
    //       'cancelButtonText': 'Cancel',
    //       'iconColor': this.siteColor,
    //       preConfirm: async (notes) => {
    //         item.notes = notes
    //         await this.$store.dispatch("sensor/updateEventNotes", item)
    //       },
    //       allowOutsideClick: () => !this.$swal.isLoading()
    //     }).then(async (result) => {
    //       if (result.isConfirmed) {
    //         this.$swal('Event notes saved!', '', 'success')
    //       }
    //     });
    //   },
    //   lastUpdated(date) {
    //     if (!date) return "In Progress";
    //     let fullDate = new Date(date).toLocaleDateString();
    //     let todayDate = new Date().toLocaleDateString();
    //     if (fullDate === todayDate) {
    //       fullDate = "Today";
    //     }
    //     return `${fullDate} - ${new Date(date).toLocaleTimeString()}`;
    //   },
    //   capitalize(word) {
    //     return word.slice(0, 1).toUpperCase() + word.slice(1);
    //   },
    //   resolved(initial, final) {
    //     if (!final) return "Not Resolved";
    //     let time = ((new Date(final) - new Date(initial)) / 60000)
    //     if (time > 1440) return `${(time / 1440).toFixed(0)} Day/s`
    //     else if (time > 60) return `${(time / 60).toFixed(0)} Hour/s`
    //     else if (time < 1) return `${(time * 60).toFixed(0)} Sec/s`
    //     else return `${time.toFixed(0)} Min/s`
    //   }
    },
  };
  </script>
  
  <style lang="scss">
    .current-product-min, .current-product-range {
      
      color: #fff;
      input, button {
          color: #fff !important;
        //   caret-color: #fff !important;
      }
    //   &:hover {
    //     background-color: #0095da !important;
    //   }
    //   .hot-icon {
    //     color: #fff !important;
    //   }
    }
    .current-product-range {
        background-color: #0095da !important;
        &:hover {
            background-color: #0095da !important;
        }
    }
    .current-product-min {
        background-color: #c40000 !important;
       .hot-icon {
            color: #fff !important;
            caret-color: #fff !important;
        }
        &:hover {
            background-color: #c40000 !important;
            // .hot-icon {
            //     color: #fff !important;
            //     caret-color: #fff !important;
            // }
        }
    }
    table {
        .v-text-field {
            border-color: #ffffff1f;
            padding-top: 0px;
            margin-top: 0px;
        }
    }
  </style>